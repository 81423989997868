<template>
  <div>
    <b-row class="justify-content-center">
      <b-col md="10" class="d-flex flex-column">
        <b-input-group>
          <b-form-input v-model="digitableLine"></b-form-input>
          <b-input-group-append>
            <b-button
              id="target-popover"
              @click="copy"
              variant="outline-secondary"
              >Copiar</b-button
            >
            <b-popover
              ref="copied"
              :show.sync="showPopover"
              custom-class="my-popover-class"
              variant="success"
              target="target-popover"
              placement="topleft"
            >
              Copiado!
            </b-popover>
          </b-input-group-append>
        </b-input-group>

        <div
          id="bank-ticket-popover"
          @click="$refs.bankTicketModal.open()"
          class="bank-card"
          v-html="barcode"
        ></div>
        <b-popover
          v-if="!$isMobile()"
          target="bank-ticket-popover"
          title="Clique para ampliar"
          triggers="hover"
          variant="info"
        >
        </b-popover>
      </b-col>
    </b-row>

    <div class="mt-3" style="font-size: 14px">
      <div v-if="$isMobile()">
        <strong>Atenção:&nbsp;</strong
        ><span
          >Para uma melhor leitura do código de barras, vire o celular na
          horizontal.</span
        >
      </div>
      <b-button v-if="!$isMobile() && !freezeButton" @click="create_pdf()"
        >Imprimir Boleto</b-button
      >
      <b-button v-if="freezeButton" disabled
        >Gerando o arquivo PDF, aguarde... <b-spinner small></b-spinner
      ></b-button>
    </div>
    <BankTicketPdf ref="pdf" />

    <SweetModal
      ref="bankTicketModal"
      overlay-theme="dark"
      width="100%"
      :hide-close-button="$isMobile()"
    >
      <div v-if="direction == 'vertical'" class="d-flex justify-content-center">
        <p class="lead">Vire o celular na horizontal</p>
      </div>
      <div
        v-else
        :class="[isMobile ? 'mobile' : 'no-mobile']"
        v-html="barcode"
      ></div>
    </SweetModal>
  </div>
</template>


<script>
import Boleto from "boleto.js";

export default {
  data() {
    return {
      showPopover: false,
      isMobile: this.$isMobile(),
      direction: "vertical",
      bank: "Inter",
      bank_gateway: "",
      freezeButton: false,
      height: "",
      width: "",
    };
  },
  components: {
    BankTicketPdf: () => import("./BankTicketPdf.vue"),
  },
  computed: {
    clientData() {
      return this.$store.state.pdf.clientData;
    },
    unityData() {
      return this.$store.state.pdf.unityData[0];
    },
    invoices() {
      return this.clientData.invoice;
    },
    bankData() {
      return this.clientData.unityBankAccount[0];
    },
    financialClientData() {
      return this.clientData.financialClient;
    },
    address() {
      const address = this.clientData.financialClient.address;

      return address;
    },
    barcode() {
      try {
        return new Boleto(this.invoices[0].line).toSVG();
      } catch (e) {
        return "erro ao gerar código de barras";
      }
    },
    digitableLine() {
      try {
        return new Boleto(this.invoices[0].line).prettyNumber();
      } catch (e) {
        return "erro ao gerar linha digitável";
      }
    },
  },
  methods: {
    async create_pdf() {
      this.freezeButton = true;
      setTimeout(async () => {
        this.freezeButton = await this.$refs.pdf.to_pdf();
      }, 200);
    },
    async copy() {
      await navigator.clipboard.writeText(this.invoices[0].line);
      const saved = await navigator.clipboard.readText();
    },
    makeToast() {
      this.$bvToast.toast("Copiado", {
        title: `Ok!`,
        variant: "success",
        solid: true,
      });
    },
  },
  mounted() {
    this.bank_gateway = this.unityData.url_gateway;

    var mql = window.matchMedia("(orientation: portrait)");

    if (mql.matches) {
      this.direction = "vertical";
      this.$refs.bankTicketModal.close();
    } else {
      this.direction = "horizontal";
      // this.$refs.bankTicketModal.open();
    }

    const self = this;

    mql.addEventListener("change", function (m) {
      if (m.matches) {
        self.direction = "vertical";
        self.$refs.bankTicketModal.close();
      } else {
        self.direction = "horizontal";
        self.$refs.bankTicketModal.open();
      }
    });
  },
};
</script>
<style scoped>
.mobile {
  margin-right: 0;
  margin-left: 0;
}
.no-mobile {
  margin-right: 100px !important;
  margin-left: 100px !important;
}
</style>
